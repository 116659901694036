<template>
    <div class="PDFViewer">
        <div class="hearderBox">
            <span @click="backPath">
                <template v-if="isWxWork">返回</template>
                <Svgs v-else name="iconfanhui" color="#000" />
            </span> 
        {{itemData.title}}
        </div>
        <!-- <PublicTitle @back-call="backPath" :title="itemData.title"/> -->
        <!--        <div id="PDFViewerContent">-->
        <div class="PDFViewerContent">
            <Skeleton v-if="isFirst" :skList="skList"></Skeleton>
            <span
                v-if="currentPage && currentPage / pageCount < 1">数据加载中，请稍后......{{ (currentPage / pageCount * 100).toFixed(2) }} %</span>
            <pdf
                v-for="i in countPages"
                :key="i"
                :src="src"
                :page="i"
                @num-pages="numPages($event)"
                @page-loaded="pageLoaded($event)"
                style="margin-top: 8px"

            ></pdf>
        </div>
    </div>
</template>

<script>
import pdf from 'vue-pdf'

export default {
    name: "PDFViewer",
    components: {
        pdf
    },
    data() {
        return {
            isWxWork: window.navigator.userAgent.toLowerCase().indexOf('wxwork') !== -1,
            currentPage: 0,
            pageCount: 0,
            isFirst: true,
            itemData: {},
            src: null,
            countPages: undefined,
            skList: [
                {
                    width: "100%",
                    height: "1.2rem",
                },
                {
                    width: "100%",
                    height: "1.2rem",
                    mt: ".3rem",
                },
                {
                    width: "100%",
                    height: "1.2rem",
                    mt: ".3rem",
                },
            ],
        };
    },
    created() {
        this.itemData = this.$route.params.itemInfo;
        console.log(this.itemData.path, "filePath");
        this.src = pdf.createLoadingTask(this.itemData.path)
        // this.pdfjsLib()
    },
    mounted() {
        this.src.promise.then(pdf => {
            this.isFirst = false;
            this.countPages = pdf.numPages;
        })
    },
    methods: {
        pdfjsLib() {
            // If absolute URL from the remote server is provided, configure the CORS
            // header on that server.
            var url = this.itemData.path;

            // Loaded via <script> tag, create shortcut to access PDF.js exports.
            var pdfjsLib = window["pdfjs-dist/build/pdf"];

            // The workerSrc property shall be specified.
            pdfjsLib.GlobalWorkerOptions.workerSrc = "//mozilla.github.io/pdf.js/build/pdf.worker.js";

            var pdfDoc = null,
                pageNumPending = null,
                scale = 0.8;

            /**
             * Get page info from document, resize canvas accordingly, and render page.
             * @param num Page number.
             */
            function renderPage(num, canvas) {
                // Using promise to fetch the page
                pdfDoc.getPage(num).then(function (page) {
                    var viewport = page.getViewport({scale: scale});
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    // Render PDF page into canvas context
                    var renderContext = {
                        canvasContext: canvas.getContext('2d'),
                        viewport: viewport,
                    };
                    var renderTask = page.render(renderContext);

                    // Wait for rendering to finish
                    renderTask.promise.then(function () {
                        if (pageNumPending !== null) {
                            // New page rendering is pending
                            renderPage(pageNumPending);
                            pageNumPending = null;
                        }
                    });
                });


            }

            /**
             * Asynchronously downloads PDF.
             */
            pdfjsLib.getDocument(url).promise.then(function (pdfDoc_) {
                pdfDoc = pdfDoc_;
                for (let index = 0; index < pdfDoc.numPages; index++) {
                    let canvasinstance = document.createElement("canvas");
                    document.querySelector("#PDFViewerContent").appendChild(canvasinstance);
                    renderPage(index + 1, canvasinstance);
                }

                // Initial/first page rendering
            });
        },
        backPath() {
            this.$router.go(-1);
        },
        numPages(e) {
            this.pageCount = e;
        },
        pageLoaded(e) {
            if (e == this.pageCount) {
                this.isFirst = false;
            }
            this.currentPage = e;
        },
    },
};
</script>

<style lang="scss">
.PDFViewer {
    // padding-top: 1rem;
    #PDFViewerContent {
        text-align: center;
        display: flex;
        flex-direction: column;
        position: fixed;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 100px;

        canvas {
            width: 90%;
            margin: 20px auto;
            border: 1px solid gray;

            &:last-child {
                margin-bottom: 200px;
            }
        }
    }
    .PDFViewerContent {
        text-align: center;
        padding: .26rem;
    }
}
</style>
